import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  createSessionWithUser,
  deleteSession,
  deleteSessionWithUser,
  editSessionWithUser,
  fetchActive,
  fetchBalance,
  fetchOpenBets,
  fetchSession,
  fetchSessionWithUser,
} from "../../services/session";
import { getColor } from "../../styles/colors";
import { formatDate, formatWithTz } from "../../libs/DateLib";
import {
  ArrayIsEmpty,
  arrayLength,
  getFirstElement,
} from "../../libs/ArrayLib";
import TitleWithBoldComplement from "../../components/TitleWithBoldComplement";
import { formatReal } from "../../libs/StringLib";
import { FaRegTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCache } from "../../services/cache";
import { useEffect, useState } from "react";
import Select from "react-select";
import { fetchUsers } from "../../services/users";

const sumStakes = (openBets) => {
  return openBets?.reduce((acumulator, { stake }) => {
    return acumulator + stake;
  }, 0);
};

const getNameBySessionStatus = (sessionStatus) => {
  if (sessionStatus) {
    return "Online";
  }

  return "Offline";
};

const ModalLinkUser = ({
  isOpen,
  onClose,
  sessionId,
  users: usersSelected,
}) => {
  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () => fetchUsers(),
  });

  const { data: sessionsWithUsers } = useQuery({
    queryKey: ["sessions-with-users", sessionId],
    queryFn: () => fetchSessionWithUser(),
    refetchInterval: 180000,
  });

  const [selectedOptions, setSelectedOptions] = useState([]);

  const queryClient = useQueryClient();

  const userOptions = users?.map(({ id, user }) => ({
    label: user,
    value: id,
  }));

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  const currentSession = sessionsWithUsers?.find(
    ({ sessionId: sessionIdItem }) => sessionIdItem === sessionId
  );

  const editSessionMutate = useMutation({
    mutationFn: () => {
      const userIds = selectedOptions.map(({ value }) => value);
      editSessionWithUser(currentSession?.id, { sessionId, userIds });
    },
    onSuccess: () => {
      onClose();
      toast.success("Sessão Editada com sucesso!");
      queryClient.invalidateQueries("sessions-with-users");
      queryClient.invalidateQueries("session");
    },
  });

  useEffect(() => {
    const setUsersToSelect = () => {
      if (ArrayIsEmpty(currentSession?.users)) {
        setSelectedOptions([]);
        return;
      }

      const userOptions = currentSession?.users.map(({ id, user }) => ({
        label: user,
        value: id,
      }));

      setSelectedOptions(userOptions);
    };

    setUsersToSelect();
  }, [currentSession?.users, sessionId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Sessão</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form>
            <Flex
              flexDirection="column"
              gap="10px"
              width="100%"
              paddingBottom="10px"
            >
              <FormControl>
                <FormLabel>Proprietários</FormLabel>
                <Select
                  value={selectedOptions}
                  isMulti
                  name="users"
                  options={userOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleChange}
                />
              </FormControl>

              <Button
                onClick={editSessionMutate.mutate}
                isLoading={editSessionMutate.isPending}
              >
                Editar
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default function Session() {
  const { id } = useParams();

  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const [loggedUser, setLoggedUser] = useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = () => {
      const user = getCache("user");
      const parsedUser = JSON.parse(user);
      const userIsValid = parsedUser?.id;

      if (!userIsValid) {
        navigate("/");
      }

      setLoggedUser(parsedUser);
      return;
    };

    verifyUser();
  }, [navigate]);

  const { data: balance, isLoading: loadingBalance } = useQuery({
    queryKey: ["balance", id],
    queryFn: () => fetchBalance(id),
    refetchInterval: 180000,
  });

  const { data: session } = useQuery({
    queryKey: ["session", id],
    queryFn: () => fetchSession(id),
    refetchInterval: 180000,
  });

  const { data: sessionIsActive, isLoading: loadingSessionIsActive } = useQuery(
    {
      queryKey: ["active", id],
      queryFn: () => fetchActive(id),
      refetchInterval: 180000,
    }
  );

  const { data: openBets, isLoading: loadingOpenBets } = useQuery({
    queryKey: ["openBets", id],
    queryFn: () => fetchOpenBets(id),
    refetchInterval: 180000,
  });

  const { data: sessionsWithUsers } = useQuery({
    queryKey: ["sessions-with-users", id],
    queryFn: () => fetchSessionWithUser(),
    refetchInterval: 180000,
  });

  const currentSession = sessionsWithUsers?.find(
    ({ sessionId }) => sessionId === id
  );

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (id) => deleteSession(id),
    onSuccess: () => {
      toast.success("Sessão deletada com sucesso!");
      queryClient.invalidateQueries("sessions-with-users");
      queryClient.invalidateQueries("sessions");
    },
  });

  const deleteSessionWithUserMutate = useMutation({
    mutationFn: () => deleteSessionWithUser(currentSession?.id),
    onSuccess: () => {
      navigate("/sessions");
    },
  });

  const statusName = getNameBySessionStatus(sessionIsActive);

  const totalStakes = sumStakes(openBets);

  const totalBalance = totalStakes + balance;

  const numberOpenBets = arrayLength(openBets);

  openBets?.sort((a, b) => formatDate(b.date) - formatDate(a.date));

  const userIsAdmin = loggedUser?.userType === "ADMIN";

  return (
    <Flex
      width="100%"
      minH="calc(100vh - 150px)"
      flexDirection="column"
      gap="10px"
      backgroundColor={getColor("CAPE")}
    >
      <Flex
        width="100%"
        padding="40px 0px"
        alignItems="center"
        px="15px"
        flexDirection={isLargerThan800 ? "row" : "column"}
      >
        <Flex
          width={isLargerThan800 ? "50%" : "100%"}
          justifyContent={isLargerThan800 ? "center" : "flex-start"}
          alignItems="center"
          gap="50px"
        >
          <Text fontWeight="700" color="white" fontSize="26px">
            {session?.account}
          </Text>
          <Flex flexDirection="column" gap="10px">
            {userIsAdmin && (
              <Button
                leftIcon={<FaRegTrashAlt />}
                backgroundColor="red"
                color="white"
                onClick={() => {
                  mutation.mutate(id);
                  deleteSessionWithUserMutate.mutate();
                }}
              >
                Excluir Sessão
              </Button>
            )}
            {userIsAdmin && (
              <Button
                backgroundColor={getColor("TOXIC_ZOE_LAVERNE")}
                color="white"
                onClick={onOpen}
              >
                Editar Sessão
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex width={isLargerThan800 ? "50%" : "100%"} flexDirection="column">
          <TitleWithBoldComplement
            title="Status: "
            boldComplement={statusName}
            loading={loadingSessionIsActive}
            isStatus={true}
            color={sessionIsActive ? getColor("DONT_EMBARRASS_ME") : "red"}
            fontSize="20px"
          />
          <TitleWithBoldComplement
            title="Saldo Total: "
            boldComplement={formatReal(totalBalance)}
            loading={loadingBalance}
            color={getColor("DONT_EMBARRASS_ME")}
            fontSize="24px"
          />
          <TitleWithBoldComplement
            title="Saldo Disponível: "
            boldComplement={formatReal(balance)}
            loading={loadingBalance}
            fontSize="18px"
            color={getColor("KINDA_DULLISH_BLUE")}
          />
          <TitleWithBoldComplement
            title="Saldo Pendente: "
            boldComplement={formatReal(totalStakes)}
            loading={loadingOpenBets}
            fontSize="16px"
            color={getColor("MUSTARD_YELLOW")}
          />
          <Flex width="100%" flexWrap="wrap" gap="10px">
            <Text color="white">Proprietários: </Text>
            {currentSession?.users.map(({ id, user }) => (
              <Text key={id} color="white" fontWeight="700">
                {user}
              </Text>
            ))}
            {ArrayIsEmpty(currentSession?.users) && "-"}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        width="100%"
        backgroundColor={getColor("TOXIC_ZOE_LAVERNE")}
        padding="10px"
      >
        <Text color="white" fontWeight="600" fontSize="20px">
          Apostas Pendentes: {numberOpenBets}
        </Text>
      </Flex>
      <Flex width="100%" flexDirection="column" gap="20px" px="15px">
        {loggedUser?.userType !== "NIVEL02" &&
          openBets?.map(({ betid, date, selections, stake, total_returns }) => {
            const formattedDate = formatWithTz(date);
            const selection = getFirstElement(selections);
            return (
              <Flex
                minW="300px"
                backgroundColor={getColor("DRAGO_DE_SAO_FRANCISCO")}
                padding="10px"
                borderRadius="8px"
                key={betid}
                flexDirection="column"
                color="white"
              >
                <Flex
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text
                    fontSize="18px"
                    fontWeight="700"
                    filter={loggedUser?.userType === "NIVEL03" && "blur(10px)"}
                    width={loggedUser?.userType === "NIVEL03" && "150px"}
                    height={loggedUser?.userType === "NIVEL03" && "30px"}
                    backgroundColor={
                      loggedUser?.userType === "NIVEL03" &&
                      "rgba(255, 255, 255, 0.5)"
                    }
                  >
                    {loggedUser?.userType !== "NIVEL03" ? selection.event : ""}
                  </Text>
                  <Text fontSize="14px">{formattedDate}</Text>
                </Flex>
                <Text
                  fontSize="14px"
                  filter={loggedUser?.userType === "NIVEL03" && "blur(10px)"}
                  width={loggedUser?.userType === "NIVEL03" && "150px"}
                  height={loggedUser?.userType === "NIVEL03" && "30px"}
                  backgroundColor={
                    loggedUser?.userType === "NIVEL03" &&
                    "rgba(255, 255, 255, 0.5)"
                  }
                >
                  {loggedUser?.userType !== "NIVEL03" ? selection.market : ""}
                </Text>
                <Text
                  fontSize="14px"
                  filter={loggedUser?.userType === "NIVEL03" && "blur(10px)"}
                  width={loggedUser?.userType === "NIVEL03" && "150px"}
                  height={loggedUser?.userType === "NIVEL03" && "30px"}
                  backgroundColor={
                    loggedUser?.userType === "NIVEL03" &&
                    "rgba(255, 255, 255, 0.5)"
                  }
                >
                  {loggedUser?.userType !== "NIVEL03" ? selection.outcome : ""}
                </Text>
                <Flex width="100%">
                  <Flex width="50%" flexDirection="column">
                    <Text fontSize="14px">Aposta</Text>
                    <Text fontWeight="700">{formatReal(stake)}</Text>
                  </Flex>
                  <Flex width="50%" flexDirection="column">
                    <Text fontSize="14px">Retornos</Text>
                    <Text fontWeight="700">{formatReal(total_returns)}</Text>
                  </Flex>
                </Flex>
              </Flex>
            );
          })}
      </Flex>
      <ModalLinkUser
        isOpen={isOpen}
        onClose={onClose}
        sessionId={id}
        users={currentSession?.users}
      />
    </Flex>
  );
}
